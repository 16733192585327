#results_body_div {
  background-color: rgba(220, 218, 218, 0.84);
  height: 100%;
}
.ant-layout-header {
  text-align: center;
  color:white !important;
  font-weight: 400 !important;
}

#ace_score_main_section {
  display: flex;
  background-color: white;
  margin-top: 30px;
  margin-left: 2.5%;
  padding: 0px;
  width: 95%;
  border-radius: 5px;
  justify-content: space-between;
  height: 250px;

}

#ace_score_main_section_left {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 67%;
}

#ace_disclaimer_section {
  width: 90%;
  color: #f14a4a;
}

#ace_disclaimer_bcbs {
  width: 100%;
  color: blue;
  font-size: 15px;
  font-weight: bold;
}


#ace_score_write_out {
  font-size: 40px;
  font-weight: bold;
}

#ace_score_explanation{
  margin-top: -50px;
}

#ace_big_text_section{
  display: block;
  float: left;
  margin-top: 20px;
  height: 50%;
}

#ace_score_in_numbers {
  font-size: 50px;
  font-weight: 800;
  padding-right: 10%;
  margin-top: 0px;
  height: 50%;
}

#ace_number_large {
  color: orange;
  font-size: 80px;
}

#ace_metadata_info {
  left: 70%;
  position:absolute;
  margin-top: 30px;
}

#ace_date_meta_main {
  font-weight: bold;
  font-size: 15px;
}

.format_meta_response {
  color: blue;
}

#ace_vertical_divider {
  border-right: 3px solid #545252;
  height: 200px;
  position:absolute;
  left: 68%;
  margin-top: 25px;
}

#ace_progress_bar_div
{
  width: 80%;
  margin-bottom: 50px;
}


#ace_progress_bar_background {
  background-color: rgba(96, 94, 94, 0.32) !important;
  border-radius: 5px;
}

#ace_progress_bar_ranking {
  background-color: red !important;
  border-radius: 5px;
}


/* SEVERITY LEVEL SECTION */
#severity_level_section {
  display: flex;
  margin-top: 30px;
  margin-left: 2.5%;
  padding: 0px;
  width: 95%;
  border-radius: 5px;
  justify-content: space-between;
  height: 250px;

}

#fear_section_div {
  width: 30%;
  background-color: rgba(0, 191, 255, 0.78);
  margin-right: 3%;
  border-radius: 10px;
}

#anger_section_div {
  width: 30%;
  background-color: rgba(255, 22, 22, 0.7);
  margin-right: 3%;
  border-radius: 10px;
}

#sadness_section_div {
  width: 30%;
  background-color: rgba(252, 133, 16, 0.84);
  border-radius: 10px;
}

.emotion_heading {
  color: white;
  font-size: 25px;
  border-radius: 80px;
  margin-top: 20px;
}

.emotion_section_text_adjust {
  margin-left: 10%;
}

.emotion_severity_label {
  font-color: white;
  font-size: 25px;
  font-weight: bold;
}

.emotion_suggestion_and_tqi {
  width: 100%;
  display: flex;
  margin-top: 15%;
}

#powered_by_tqi_msg {
  font-size: 13px;
  color: #3b3e3f;
}

#suggestion_text {
  font-size: 16px;
}

#bcbs_hyperlink > a {
  font-size: 18px;
  color: blanchedalmond;
}

#bcbs_hyperlink > a:hover {
  font-size: 18px;
  color: rgba(0, 0, 255, 0.53);
}




.ending_section_with_logos {
  background-color: white;
  margin-top: 30px;
  margin-left: 2.5%;
  padding: 0px;
  width: 95%;
  border-radius: 5px;
  justify-content: space-between;
  height: 250px;
  display: flex;
}

.tqi_bottom_logo {
  width: 300px;
}


.bcbs_bottom_logo {
  width: 300px;
}



