
body, #root{
    height:100%;
    margin:0;
    background-color: #EFEFEF !important;
}
.survey{
    height: 100%;
}

div.sd-root-modern {
     background-color: #EFEFEF !important;
}

.sd-question__title.sd-question__title--required{
    height: 250px;
}

.sd-boolean__thumb {
    background: #2EB5B9 !important;
}

.sd-boolean__thumb-text {
    color: black;
}

.sd-boolean__indeterminate {
    background: #EFEFEF !important;
}
/*.sd-scrollable-container:hover */
/*
.sd-boolean__switch:hover span.sd-boolean__label {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15) !important;
    border-radius: 50px !important;
    background: #2EB5B9 !important;
}
*/


.sd-boolean__label:hover {
     box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15) !important;
    border-radius: 50px !important;
    background: #2EB5B9 !important;
}


.sd-boolean__switch {
    pointer-events: none;
}

#header_menu {
    margin-top: 20px;
    margin-right: 30px;
    padding: 5px;
    font-size: 20px;
    color: #3c3f41 !important;
    width: 500px;
    float: right;
    margin-left: 30px !important;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 40px;
}

#header_logo {
    width: 200px;
    margin-left: 80px;
    margin-top: 5px;
    float:left;
}

#header_menu > a {
    color: #0d3f5e !important;
    #color: #009593 !important;
}

#header_menu > a:hover {
    color: #009593 !important;
    font-size: 23px;
}

.sv_custom_header {
    float: none !important;
}

#tqi_image_logo {
    max-height:100px !important;
    max-width:350px !important;
}

#tqi_general_header {
    float: none !important;
    #display: flex;
    #background-color: rgba(0, 173, 0, 0.12);
    background-color: rgb(243, 198, 19);
    height: 80px
}

.sd-row {

    width: 80% !important;
    margin-left: 10%;
}

#sv-nav-prev {
    margin-left: 10%;
}

.sd-title {
    margin-left: 10% !important;
    font-color: #009593 !important;
    color: #305b5b !important;
    font-weight: normal !important;
}

.sd-body .sd-body__page {
    padding: 5px;
}


/* AUDIO RECORDING CSS */
#audio_recorder_instructions {
    display: inline !important;
    margin: 30px;
    margin-inline-end: 0px;
}

#recorder_instructions_list li{
    padding-right: 90px;
    color: #009593 !important;
    font-size: 20px;
    text-align: center !important;
    display: inline !important;
}

#recorder_text_heading{
    color: rgb(0, 173, 0) !important;
    font-size: 40px;
    text-align: center;
}

#recorder_text_heading > p {
    margin-bottom: 35px;
    margin-top: -10px;
}


#recorder_text_to_read{
    padding: 5px;
    margin-top: -40px;
    color: #0e4362 !important;
    font-size: 23px;
    text-align: center;
    background-color: rgba(243, 204, 23, 0.18);
    #background-color: rgb(0, 169, 165);
    #background-color: rgb(0, 75, 117);
    #background-color: rgb(0, 173, 0);
    #background-color: rgb(109, 201, 29);
    border: 5px solid rgb(0, 75, 117);
    border-radius: 20px;
    width: 60%;
    margin-left: 20%;

}



/* AUDIO VIEW PART */
#duration_display {
    color: rgba(0, 0, 0, 0.57) !important;
    font-size: 30px !important;
    text-align: center;
    margin-top: 10px;
}
#microphone_instructions {
    font-size: 20px;
    color: black;
}

#audio_replay_bar {
    height: 40px !important;
}

#microphone_recorder_button {
    width: 50px !important;
    height: 50px !important;
    left: 50% !important;
    background: rgb(0, 75, 117);
    padding: -60px !important;
    margin-top: -25px !important;
}

#get_results_button {
    margin-top: -103px;
}

#get_results_button > button {
    background-color: rgb(243, 204, 23);
    color: darkred;
}

#get_results_button > button:hover {
    color: rgb(0, 169, 165);
}

#recording_box_area {
    background: rgba(109, 201, 29, 0.5) !important;
    border-radius: 25px;
    border: 2px solid rgba(115, 173, 33, 0.16);
    height: 20px;
    border: 3px #0d3f5e;
}

#recording_inner_div {
    background: rgba(255, 255, 255, 0);
}

#recording_main_box_div {
    padding: 10px;
}

#recorder_pause_stop_buttons {
    margin-left: 7px;
}