.prediction {
  padding-top: 30px;
}
.ant-layout-header {
  text-align: center;
  color:white !important;
  font-weight: 400 !important;
}

.ant-layout-footer {
  line-height: 1.5;
}

.ant-layout-content {
  padding:30px;
  height:240px;
  color: #fff;
  line-height: 60px;
  background: #EFEFEF;
}

[class~='ant-row'] {

  background: #EFEFEF;
  border-width: 1px;
  border-color: #EFEFEF;
  border-style: solid;
  font-size: 30px;
}

.ant-col div{
  box-sizing: border-box !important;
  background: #2EB5B9;
  border-width: 1px;
  text-align: center;
}

.result {
  padding-top: 20px;
}

.result .ant-col div{
  border-radius: 50%;
}

.high {
  background: red !important;
}

.medium {
  background: #fdd301 !important;
}

.low {
  background: blue !important;
}

.title {
  font-size: 30px
}

.text {
  color: blue;
}

.audio-loader {
  height:20vh;
  width: 20%;
  /* background: #fff; */
  position: fixed;
  z-index: 10;
  left: 50%;
  top: 70%;
}
